const PROJECT_ID = '125270652525';
const WORKLOAD_IDENTITY_POOL_ID = 'nuclivision-pool';

async function getGoogleAccessToken(firebaseUser) {
  try {
    // Step 1: Retrieve the Firebase OIDC Token
    const subjectToken = await firebaseUser.getIdToken();

    // Step 2: Exchange OIDC Token for Google Access Token
    const stsTokenResponse = await fetch('https://sts.googleapis.com/v1/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        grant_type: 'urn:ietf:params:oauth:grant-type:token-exchange',
        audience: `//iam.googleapis.com/projects/${PROJECT_ID}/locations/global/workloadIdentityPools/${WORKLOAD_IDENTITY_POOL_ID}/providers/firebase-oidc`,
        subject_token_type: 'urn:ietf:params:oauth:token-type:jwt',
        requested_token_type: 'urn:ietf:params:oauth:token-type:access_token',
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        subject_token: subjectToken,
      }),
    });

    const stsData = await stsTokenResponse.json();

    if (!stsTokenResponse.ok) {
      throw new Error(`STS token exchange failed: ${stsData.error}`);
    }

    return stsData.access_token;
  } catch (error) {
    console.error('Failed to get Google access token:', error);
    throw error;
  }
}

export default getGoogleAccessToken;
