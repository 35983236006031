import React, { useEffect, useState } from 'react';
import { auth } from '../index';
import { signInWithCustomToken } from 'firebase/auth';

const LinkedInAuth = () => {
  const [loading, setLoading] = useState(true);
  // Utility functions for URL parameter and Firebase project ID retrieval
  const getURLParameter = name => {
    return (
      decodeURIComponent(
        (new RegExp(`[?|&]${name}=([^&;]+?)(&|#|;|$)`).exec(window.location.search) || [
          null,
          '',
        ])[1].replace(/\+/g, '%20')
      ) || null
    );
  };

  // Main LinkedIn auth flow logic
  useEffect(() => {
    window.tokenReceived = async data => {
      if (data.token) {
        await signInWithCustomToken(auth, data.token).then(() => {
          window.close();
        });
      } else {
        console.error('Callback error:', data);
        document.body.innerText = `Error in the token Function: ${data.error}`;
      }
    };

    const code = getURLParameter('code');
    const state = getURLParameter('state');
    const error = getURLParameter('error');

    if (error) {
      document.body.innerText = `Error back from the LinkedIn auth page: ${error}`;
    } else if (!code) {
      window.location.href = `https://us-central1-nuclivision-user-portal.cloudfunctions.net/redirectToLinkedin`;
    } else {
      // Use JSONP to load the 'token' Firebase Function for Firebase custom token
      const script = document.createElement('script');
      script.type = 'text/javascript';
      const tokenFunctionURL = `https://us-central1-nuclivision-user-portal.cloudfunctions.net/getLinkedinToken`;
      script.src = `${tokenFunctionURL}?code=${encodeURIComponent(code)}&state=${encodeURIComponent(state)}&callback=tokenReceived`;
      document.head.appendChild(script);
    }
  }, []); // Run on initial render

  return (
    <div className="flex h-screen items-center justify-center bg-gray-100">
      {loading ? (
        <div className="flex flex-col items-center justify-center space-y-4">
          <div className="h-10 w-10 animate-spin rounded-full border-4 border-t-4 border-gray-300 border-t-primary-active" />
          <p className="text-lg text-primary-active">Authenticating with LinkedIn...</p>
        </div>
      ) : (
        <p>Please wait...</p>
      )}
    </div>
  );
};

export default LinkedInAuth;
