import React, { useState } from 'react';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../index.js';
import FooterComponent from './FooterComponent';
import getGoogleAccessToken from '../utils/getGoogleAccessToken';

function SignupComponent({ userAuthenticationService }) {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSignup = async event => {
    event.preventDefault();
    setError(null);

    // Check if passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match!');
      return;
    }

    try {
      // Create a new user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      updateProfile(user, { displayName: name });

      // Set user in authentication service (if needed)
      const token = await getGoogleAccessToken(user);

      // Update the user object with the token
      const googleUser = { accessToken: token };
      userAuthenticationService.setUser(googleUser);

      // Navigate to home page or another route on successful signup
      navigate('/');
    } catch (error) {
      if (error.code === 'auth/weak-password') {
        setError('Password should be at least 6 characters');
      } else if (error.code === 'auth/email-already-in-use') {
        setError('Email already in use.');
      } else {
        setError(error.message); // Handle other errors
      }
    }
  };

  return (
    <div className="flex h-screen flex-col bg-gray-100">
      <div className="flex flex-grow items-center justify-center">
        <div className="flex flex-col items-center justify-center space-y-8">
          <div className="mx-auto max-w-xl space-y-4 rounded-lg bg-secondary-dark py-8 px-8">
            <div className="space-y-2 text-center">
              <h2 className="text-2xl font-semibold text-primary-active">Sign Up</h2>
              {error && <p className="text-red-500">{error}</p>}
            </div>
            <form
              onSubmit={handleSignup}
              className="space-y-4"
            >
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="w-full rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary-active"
              />
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={e => setName(e.target.value)}
                className="w-full rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary-active"
              />
              <div></div>
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                className="w-full rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary-active"
              />
              <input
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                className="w-full rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary-active"
              />
              <div className="flex flex-col space-y-4">
                <button
                  onClick={handleSignup}
                  className="w-full rounded-lg bg-primary-active py-2 text-white transition hover:bg-primary-dark"
                >
                  Sign Up
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  );
}

export default SignupComponent;
